export default [
  {
    header: 'Dashboard',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Stage 1 Analytics',
        route: 'Stage1',
        icon: 'ActivityIcon',
      },
      {
        title: 'Stage 2 Analytics',
        route: 'Stage2',
        icon: 'ActivityIcon',
      },
    ],
  },
]
